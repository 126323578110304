import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStatusService } from '../../../../core/interfaces/IStatusService.interface';
import { environment } from '../../../../../environments/environment';
import { IncidentActionPlanStatusUpdateDetailsDTO, IncidentActionPlanStatusUpdateDTO, IncidentActionPlanStatusUpdateFormDTO } from '../dto/incident-action-plan-status-update.dto';

@Injectable({
  providedIn: 'root',
})
export class IncidentActionPlanStatusUpdateService implements IStatusService {
  apiUrl = environment.apiUrl + '/incidents';

  constructor(protected http: HttpClient) {}

  getLatestStatus(incidentId: number, actionPlanId: number): Observable<any> {
    const url = `${this.apiUrl}/${incidentId}/action-plans/${actionPlanId}/status-updates/latest`;
    return this.http.get<any>(url);
  }

  listStatusUpdatesHistory(
    incidentId: number,
    actionPlanId: number
  ): Observable<IncidentActionPlanStatusUpdateDTO[]> {
    let params = new HttpParams();
    const url = `${this.apiUrl}/${incidentId}/action-plans/${actionPlanId}/status-updates`;
    return this.http.get<IncidentActionPlanStatusUpdateDTO[]>(
        url,
      { params }
    );
  }

  createStatusUpdate(
    incidentId: number,
    item: IncidentActionPlanStatusUpdateFormDTO,
    actionPlanId: number
  ): Observable<IncidentActionPlanStatusUpdateFormDTO> {

    const url = `${this.apiUrl}/${incidentId}/action-plans/${actionPlanId}/status-updates`;
    return this.http.post<IncidentActionPlanStatusUpdateFormDTO>(
      url,
      item
    );
  }

  getStatusUpdateByHistoryId(
    incidentId: number,
    updateId: number
  ): Observable<IncidentActionPlanStatusUpdateDetailsDTO> {
    throw new Error('Method not required.');
  }

  updateStatusUpdateByHistoryId(
    incidentId: number,
    historyId: number,
    item: IncidentActionPlanStatusUpdateFormDTO,
    actionPlanId: number
  ): Observable<IncidentActionPlanStatusUpdateFormDTO> {

    const url = `${this.apiUrl}/${incidentId}/action-plans/${actionPlanId}/status-updates/${historyId}`;
    return this.http.put<IncidentActionPlanStatusUpdateFormDTO>(
        url,
        item
    );
  }
}